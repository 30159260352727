export const REFRESHTABLE_EMPLOYEE = "REFRESHTABLE_EMPLOYEE";
export const SETDATA_EMPLOYEE = "SETDATA_EMPLOYEE";

// Add Dialog Constants
export const DIALOG_EMPLOYEE_ADD_CLEARFIELD = "DIALOG_EMPLOYEE_ADD_CLEARFIELD";
export const DIALOG_EMPLOYEE_ADD = "DIALOG_EMPLOYEE_ADD";
export const DIALOG_EMPLOYEE_ADD_LOADING = "DIALOG_EMPLOYEE_ADD_LOADING";

// Edit Dialog Constants
export const DIALOG_EMPLOYEE_EDIT_CLEARFIELD = "DIALOG_EMPLOYEE_EDIT_CLEARFIELD";
export const DIALOG_EMPLOYEE_EDIT = "DIALOG_EMPLOYEE_EDIT";
export const DIALOG_EMPLOYEE_EDIT_LOADING = "DIALOG_EMPLOYEE_EDIT_LOADING";

// Delete Dialog Constants
export const DIALOG_EMPLOYEE_DELETE = "DIALOG_EMPLOYEE_DELETE";
export const DIALOG_EMPLOYEE_DELETE_LOADING = "DIALOG_EMPLOYEE_DELETE_LOADING";

// Organization and Benefit Group Constants
export const SET_ORGANIZATION_AND_BG = "SET_ORGANIZATION_AND_BG";


// ใน employeeConstants.js เพิ่ม constants ใหม่
export const DIALOG_EMPLOYEE_IMPORT = 'DIALOG_EMPLOYEE_IMPORT';
export const DIALOG_EMPLOYEE_IMPORT_LOADING = 'DIALOG_EMPLOYEE_IMPORT_LOADING';
export const DIALOG_EMPLOYEE_IMPORT_CLEARFIELD = 'DIALOG_EMPLOYEE_IMPORT_CLEARFIELD';


export const SET_EMPLOYEE_BENEFIT_ACTIVE = 'SET_EMPLOYEE_BENEFIT_ACTIVE';


export const CHECK_PHONE_BENEFIT_LOADING = 'CHECK_PHONE_BENEFIT_LOADING';
export const CHECK_PHONE_BENEFIT_SUCCESS = 'CHECK_PHONE_BENEFIT_SUCCESS';
export const CHECK_PHONE_BENEFIT_ERROR = 'CHECK_PHONE_BENEFIT_ERROR';
