import { lazy } from "react";
const UserProfile = lazy(() => import("./components/UserManagement/UserProfile"));
const StoreProfile = lazy(() => import("./components/StoreManagement/StoreProfile"));
const GenerateCouponCode = lazy(() => import("./components/CouponVoucherManagement/GenerateCouponCode"));
const ImportCouponCode = lazy(() => import("./components/CouponVoucherManagement/ImportCouponCode"));
const MenuFromEIOS = lazy(() => import("./components/SyncData/MenuFromEIOS"));
const TestMap = lazy(() => import("./components/TestMap"));
const EmployeeProfile = lazy(() => import("./components/EmployeeManagement/EmployeeProfile"));

export const routes = [ 
    {
        path: "/employeemanagement/employeeprofile",  // ตรงกับ path ที่กำหนดใน database
        exact: true,
        component: EmployeeProfile,
        name: "employeeprofile"
    },  
    {
        path: "/usermanagement/userprofile",
        exact: true,
        component: UserProfile,
        name: "userprofile"
    },
    {
        path: "/storemanagement/storeprofile",
        exact: true,
        component: StoreProfile,
        name: "storeprofile"
    },
    {
        path: "/generatecouponcode",
        exact: true,
        component: GenerateCouponCode,
        name: "generatecouponcode"
    },
    {
        path: "/importcouponcode",
        exact: true,
        component: ImportCouponCode,
        name: "importcouponcode"
    },    
    {
        path: "/syncdata/menufromeios",
        exact: true,
        component: MenuFromEIOS,
        name: "menufromeios"
    },
    {
        path: "/testmap",
        exact: true,
        component: TestMap,
        name: "testmap"
    }
];