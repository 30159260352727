import {
  REFRESHTABLE_EMPLOYEE,
  SETDATA_EMPLOYEE,
  DIALOG_EMPLOYEE_ADD,
  DIALOG_EMPLOYEE_ADD_LOADING,
  DIALOG_EMPLOYEE_ADD_CLEARFIELD,
  DIALOG_EMPLOYEE_DELETE,
  DIALOG_EMPLOYEE_DELETE_LOADING,
  DIALOG_EMPLOYEE_EDIT,
  DIALOG_EMPLOYEE_EDIT_LOADING,
  DIALOG_EMPLOYEE_EDIT_CLEARFIELD,
  SET_ORGANIZATION_AND_BG,
  SET_EMPLOYEE_BENEFIT_ACTIVE,
  DIALOG_EMPLOYEE_IMPORT,
  DIALOG_EMPLOYEE_IMPORT_LOADING,
  DIALOG_EMPLOYEE_IMPORT_CLEARFIELD,
  CHECK_PHONE_BENEFIT_LOADING,
  CHECK_PHONE_BENEFIT_SUCCESS,
  CHECK_PHONE_BENEFIT_ERROR 
} from '../constants/employeeConstants';
import _ from 'lodash';

const initialState = {
  refreshtable: false,
  data: [],
  dialogadd: {
    clearfield: false,
    open: false,
    loading: false,
    data: null
  },
  dialogedit: {
    clearfield: false,
    open: false,
    loading: false,
    data: null
  },
  dialogdelete: {
    open: false,
    loading: false,
    data: null
  },
  organizationAndBG: {
    organizations: [],
    benefitGroups: []
  },
  employeeBenefitActive: {
    employeeBenefits: []
  },
  dialogimport: {
    clearfield: false,
    open: false,
    loading: false,
    importErrors: null
  },
  phoneCheck: {
    loading: false,
    error: null,
    data: null
  }
};

const employeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case REFRESHTABLE_EMPLOYEE:
      let dialogdata = {}
      if (action.payload) {
        dialogdata = {
          dialogadd: {
            open: false,
            loading: false,
            data: null
          },
          dialogedit: {
            open: false,
            loading: false,
            data: null
          },
          dialogdelete: {
            open: false,
            loading: false,
            data: null
          }
        }
      };
      return {
        ...state,
        ...dialogdata,
        refreshtable: action.payload,
      };

    case SETDATA_EMPLOYEE:
      return {
        ...state,
        data: action.payload
      };

    // Add Dialog Cases
    case DIALOG_EMPLOYEE_ADD:
      return {
        ...state,
        dialogadd: action.payload
      };

    case DIALOG_EMPLOYEE_ADD_CLEARFIELD:
      return {
        ...state,
        dialogadd: {
          ...state.dialogadd,
          clearfield: action.payload,
        }
      };

    case DIALOG_EMPLOYEE_ADD_LOADING:
      return {
        ...state,
        dialogadd: {
          ...state.dialogadd,
          loading: action.payload,
        }
      };

    // Edit Dialog Cases
    case DIALOG_EMPLOYEE_EDIT:
      return {
        ...state,
        dialogedit: action.payload
      };

    case DIALOG_EMPLOYEE_EDIT_CLEARFIELD:
      return {
        ...state,
        dialogedit: {
          ...state.dialogedit,
          clearfield: action.payload,
        }
      };

    case DIALOG_EMPLOYEE_EDIT_LOADING:
      return {
        ...state,
        dialogedit: {
          ...state.dialogedit,
          loading: action.payload,
        }
      };

    // Delete Dialog Cases
    case DIALOG_EMPLOYEE_DELETE:
      return {
        ...state,
        dialogdelete: action.payload
      };

    case DIALOG_EMPLOYEE_DELETE_LOADING:
      return {
        ...state,
        dialogdelete: {
          ...state.dialogdelete,
          loading: action.payload,
        }
      };

    // Organization and Benefit Group Case
    case SET_ORGANIZATION_AND_BG:
      return {
        ...state,
        organizationAndBG: action.payload
      };

    case SET_EMPLOYEE_BENEFIT_ACTIVE:
      return {
        ...state,
        employeeBenefitActive: {
          employeeBenefits: action.payload.employeeBenefits
        }
      };

    // Import Dialog Cases
    case DIALOG_EMPLOYEE_IMPORT:
      return {
        ...state,
        dialogimport: {
          ...state.dialogimport,
          open: action.payload.open
        },
        error: {
          ...state.error,
          import: null
        }
      };

    case DIALOG_EMPLOYEE_IMPORT_LOADING:
      return {
        ...state,
        dialogimport: {
          ...state.dialogimport,
          loading: action.payload
        }
      };

    case DIALOG_EMPLOYEE_IMPORT_CLEARFIELD:
      return {
        ...state,
        dialogimport: {
          ...state.dialogimport,
          clearfield: action.payload
        }
      };

    // Phone Check Cases
    case CHECK_PHONE_BENEFIT_LOADING:
      return {
        ...state,
        phoneCheck: {
          ...state.phoneCheck,
          loading: action.payload
        }
      };

    case CHECK_PHONE_BENEFIT_SUCCESS:
      return {
        ...state,
        phoneCheck: {
          loading: false,
          error: null,
          data: action.payload
        }
      };

    case CHECK_PHONE_BENEFIT_ERROR:
      return {
        ...state,
        phoneCheck: {
          loading: false,
          error: action.payload,
          data: null
        }
      };

    default:
      return state;
  }
};

export default employeeReducer;